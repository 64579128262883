import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import {
  IoPersonOutline,
  IoExitOutline,
  IoSettingsOutline,
  IoCopyOutline,
  IoPeopleOutline,
  IoCartOutline,
  IoImageOutline,
  IoAppsOutline,
  IoMegaphoneOutline,
  IoAlbumsOutline,
  IoPricetagsOutline,
  IoGridOutline,
  IoBarChartOutline,
  IoLeafOutline,
  IoGolfOutline,
  IoReaderOutline,
  IoRocketOutline,
  IoInformationCircleOutline,
  IoUnlinkOutline,
  IoAddCircleOutline,
  IoCashOutline,
  IoTrendingUp,
  IoBagCheckOutline,
  IoLayersOutline,
  IoDuplicateOutline,
  IoImagesOutline,
  IoListOutline,
  IoImages,
  IoMedicalOutline,
} from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { logoutAdmin } from "../actions/AuthAction";
import { isPermitted } from "../utils/PermissionManager";
import { Link } from "react-router-dom";

export default function SideMenu() {
  const [sideMenus, setSideMenus] = useState([]);

  const profile = useSelector((state) => state.config.config?.profile);

  const roles = profile ? profile.role : [];

  const dispatch = useDispatch();

  let menus = [
    {
      name: "inventory",
      icon: <IoLayersOutline />,
      child: [
        {
          name: "product list",
          link: "/products",
          icon: <IoAppsOutline />,
        },
        {
          name: "categories",
          link: "/categories",
          icon: <IoGridOutline />,
        },
        {
          name: "brands",
          link: "/brands",
          icon: <IoPricetagsOutline />,
        },
        {
          name: "simple product",
          link: "/product",
          icon: <IoAddCircleOutline />,
        },
        {
          name: "combo product",
          link: "/product/combo",
          icon: <IoDuplicateOutline />,
        },
      ],
    },
    {
      name: "crm",
      icon: <IoPeopleOutline />,
      child: [
        {
          name: "customers",
          icon: <IoPeopleOutline />,
          link: "/customers",
        },
      ],
    },
    {
      name: "orders",
      icon: <IoCartOutline />,
      link: "/orders",
    },
    {
      name: "payments",
      icon: <IoCashOutline />,
      link: "/payments",
    },
    {
      name: "report",
      icon: <IoTrendingUp />,
      child: [
        {
          name: "order",
          icon: <IoBagCheckOutline />,
          link: "/order-report",
        },
        {
          name: "customer",
          icon: <IoPersonOutline />,
          link: "/customer-report",
        },
        {
          name: "product",
          icon: <IoBagCheckOutline />,
          link: "/product-report",
        },
      ],
    },
  ];

  useEffect(() => {
    setSideMenus(menus);
  }, []);
  return (
    <div>
      <Link to="/">
        <img
          // src="https://tranontwebsite.s3.us-west-2.amazonaws.com/icons/Tranont_PrimaryLogo_Horizontal_Tranont_PrimaryLogo_Horizontal_Black.png"
          src={require("../assets/logo.png")}
          style={{
            width: "100%",
            objectFit: "contain",
            padding: "16px 16px",
          }}
          alt=""
        />
      </Link>
      <Menu
        theme="light"
        mode="inline"
        // defaultSelectedKeys={[path.toString()]}
        defaultOpenKeys={[]}
        style={{ padding: "8px" }}
      >
        <Menu.Item
          key="/dashboard"
          icon={
            <IoBarChartOutline style={{ fontSize: "14px", width: "18px" }} />
          }
        >
          <Link to={"/"}>Dashboard</Link>
        </Menu.Item>

        {menus.map((element) => {
          // console.log('sidebar.permiss', sidebar.permissions)
          // console.log('item.permissions.includes(roleNow)', sidebar.permissions.includes(roleNow))
          // && dev_server === sidebar.dev
          if (element.child?.length > 0) {
            return (
              <Menu.SubMenu
                key={element.name}
                icon={element.icon}
                title={element.name}
                style={{ textTransform: "capitalize" }}
              >
                {element.child.map((menu) => (
                  <Menu.Item key={menu.name} icon={menu.icon}>
                    <Link
                      to={menu.link}
                      style={{ textTransform: "capitalize" }}
                    >
                      {menu.name}
                    </Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            );
          } else {
            return (
              <Menu.Item key={element.name} icon={element.icon}>
                <Link to={element.link} style={{ textTransform: "capitalize" }}>
                  {element.name}
                </Link>
              </Menu.Item>
            );
          }
        })}

        <Menu.SubMenu
          key="appearance"
          icon={<IoLeafOutline />}
          title={"Appearance"}
          style={{ textTransform: "capitalize" }}
        >
          <Menu.Item
            key="/banner/desktopslider"
            icon={
              <IoImageOutline style={{ fontSize: "14px", width: "18px" }} />
            }
          >
            <Link to={"/banner/desktopslider"}>Desktop Banner</Link>
          </Menu.Item>
          <Menu.Item
            key="/banner/mobileslider"
            icon={<IoImages style={{ fontSize: "14px", width: "18px" }} />}
          >
            <Link to={"/banner/mobileslider"}>Mobile Banner</Link>
          </Menu.Item>
          <Menu.Item
            key="/banner/dualbanner"
            icon={
              <IoImagesOutline style={{ fontSize: "14px", width: "18px" }} />
            }
          >
            <Link to={"/banner/dualbanner"}>Dual Banner</Link>
          </Menu.Item>
          <Menu.Item
            key="/banner/quadbanner"
            icon={<IoGridOutline style={{ fontSize: "14px", width: "18px" }} />}
          >
            <Link to={"/banner/quadbanner"}>Quad Banner</Link>
          </Menu.Item>
          <Menu.Item
            key="/banner/popup"
            icon={
              <IoAlbumsOutline style={{ fontSize: "14px", width: "18px" }} />
            }
          >
            <Link to={"/banner/popup"}>Popup</Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu
          key="blog"
          icon={<IoMedicalOutline />}
          title={"Blog"}
          style={{ textTransform: "capitalize" }}
        >
          <Menu.Item
            key="/blog/posts"
            icon={<IoListOutline style={{ fontSize: "14px", width: "18px" }} />}
          >
            <Link to={"/blog/posts"}>Posts</Link>
          </Menu.Item>
          <Menu.Item
            key="/blog/categories"
            icon={
              <IoAlbumsOutline style={{ fontSize: "14px", width: "18px" }} />
            }
          >
            <Link to={"/blog/categories"}>Categories</Link>
          </Menu.Item>
          <Menu.Item
            key="/blog/manage-post"
            icon={
              <IoAddCircleOutline style={{ fontSize: "14px", width: "18px" }} />
            }
          >
            <Link to={"/blog/manage-post"}>New Post</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key="promotions"
          icon={<IoGolfOutline />}
          title={"Promotion"}
        >
          <Menu.Item
            key="/coupon"
            icon={
              <IoPricetagsOutline style={{ fontSize: "14px", width: "18px" }} />
            }
          >
            <Link to={"/coupon"}>Coupon</Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu
          key="settings"
          icon={<IoSettingsOutline />}
          title={"Settings"}
          style={{ textTransform: "capitalize" }}
        >
          <Menu.Item
            key={"blocks"}
            icon={<IoListOutline style={{ fontSize: "14px", width: "18px" }} />}
          >
            <Link
              to={`/settings/home-block`}
              style={{ textTransform: "capitalize" }}
            >
              Home Block
            </Link>
          </Menu.Item>
          {/* <Menu.Item
            key={"pages"}
            icon={
              <IoReaderOutline style={{ fontSize: "14px", width: "18px" }} />
            }
          >
            <Link to={`/pages`} style={{ textTransform: "capitalize" }}>
              Pages
            </Link>
          </Menu.Item> */}
          <Menu.Item
            key={"delivery"}
            icon={
              <IoRocketOutline style={{ fontSize: "14px", width: "18px" }} />
            }
          >
            <Link
              to={`/settings/delivery-charge`}
              style={{ textTransform: "capitalize" }}
            >
              Delivery
            </Link>
          </Menu.Item>
          <Menu.Item
            key={"businessinfo"}
            icon={
              <IoInformationCircleOutline
                style={{ fontSize: "14px", width: "18px" }}
              />
            }
          >
            <Link
              to={`/settings/business-info`}
              style={{ textTransform: "capitalize" }}
            >
              Business Info
            </Link>
          </Menu.Item>
          <Menu.Item
            key={"social-links"}
            icon={
              <IoUnlinkOutline style={{ fontSize: "14px", width: "18px" }} />
            }
          >
            <Link
              to={`/settings/social-link`}
              style={{ textTransform: "capitalize" }}
            >
              Social Links
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.Item
          key="/admins"
          icon={<IoPeopleOutline style={{ fontSize: "14px", width: "18px" }} />}
        >
          <Link to={"/admins"}>Admins</Link>
        </Menu.Item>

        <Menu.Item
          key="/profile "
          icon={<IoPersonOutline style={{ fontSize: "14px", width: "18px" }} />}
        >
          <Link to={"/profile"}>Profile</Link>
        </Menu.Item>
        <Menu.Item
          key="/logout"
          onClick={() => {
            dispatch(
              logoutAdmin(() => {
                window.location.href = "/";
              })
            );
          }}
          icon={<IoExitOutline style={{ fontSize: "16px", width: "18px" }} />}
        >
          Logout
        </Menu.Item>
      </Menu>
    </div>
  );
}
